import cn from 'classnames'

type HamburgerButtonsProps = {
  toggleMenu: () => void
  isMenuOpen: boolean
  isDarkNavigation: boolean
}

export const HamburgerButton = ({toggleMenu, isMenuOpen, isDarkNavigation}: HamburgerButtonsProps) => {
  return (
    <button
      aria-label="Menu button"
      className="relative grid h-10 w-10 place-content-center lg:hidden"
      type="button"
      onClick={toggleMenu}
    >
      <div
        className={cn(
          'h-0.5 w-4 rounded-full transition-all duration-300 span-full',
          {'-translate-y-1': !isMenuOpen},
          {'rotate-45': isMenuOpen},
          {'bg-moss-white': isDarkNavigation},
          {'bg-moss-black': !isDarkNavigation},
        )}
      />
      <div
        className={cn(
          'h-0.5 w-4 rounded-full bg-moss-black transition-all duration-300 span-full',
          {'translate-y-1': !isMenuOpen},
          {'-rotate-45': isMenuOpen},
          {'bg-moss-white': isDarkNavigation},
          {'bg-moss-black': !isDarkNavigation},
        )}
      />
      <div
        className={cn(
          'h-0.5 w-4 rounded-full bg-moss-black transition-all duration-300 span-full',
          {'translate-y-0': !isMenuOpen},
          {'-rotate-45': isMenuOpen},
          {'bg-moss-white': isDarkNavigation},
          {'bg-moss-black': !isDarkNavigation},
        )}
      />
    </button>
  )
}
