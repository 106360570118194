import {isFilled} from '@prismicio/client'
import {PrismicRichText, SliceZone} from '@prismicio/react'
import cn from 'classnames'
import Image from 'next/image'
import {useRouter} from 'next/router'
import {useLinkResolver} from 'prismicio'
import {AllDocumentTypes, NavigationDocumentData, PageDocumentData} from 'prismicio-types'
import {Fragment, memo, useCallback, useState} from 'react'
import {components} from 'slices'
import {Icon, IconType} from 'src/common/Icon'
import {Link} from 'src/common/Link'
import {Context} from 'src/common/types'
import {CookieConsentPopup} from './CookieConsentPopup'
import deFlag from './assets/de-flag.svg'
import euFlag from './assets/eu-flag.svg'
import gbFlag from './assets/gb-flag.svg'
import nlFlag from './assets/nl-flag.svg'

type Locale = 'en-us' | 'en-gb' | 'de-de' | 'nl-nl'

const langs: Record<Locale, Record<Locale, {icon: IconType; name: string; title: string}>> = {
  'en-us': {
    'en-us': {icon: euFlag, title: 'Language', name: 'EU'},
    'en-gb': {icon: euFlag, title: 'Language', name: 'EU'},
    'de-de': {icon: euFlag, title: 'Language', name: 'EU'},
    'nl-nl': {icon: euFlag, title: 'Language', name: 'EU'},
  },
  'en-gb': {
    'en-us': {icon: gbFlag, title: 'Language', name: 'United Kingdom'},
    'en-gb': {icon: gbFlag, title: 'Language', name: 'United Kingdom'},
    'de-de': {icon: gbFlag, title: 'Language', name: 'Vereinigtes Königreich'},
    'nl-nl': {icon: gbFlag, title: 'Language', name: 'Verenigd Koninkrijk'},
  },
  'de-de': {
    'en-us': {icon: deFlag, title: 'Sprache', name: 'Germany'},
    'en-gb': {icon: deFlag, title: 'Sprache', name: 'Germany'},
    'de-de': {icon: deFlag, title: 'Sprache', name: 'Deutschland'},
    'nl-nl': {icon: deFlag, title: 'Sprache', name: 'Duitsland'},
  },
  'nl-nl': {
    'en-us': {icon: nlFlag, title: 'Taal', name: 'Netherlands'},
    'en-gb': {icon: nlFlag, title: 'Taal', name: 'Netherlands'},
    'de-de': {icon: nlFlag, title: 'Taal', name: 'Niederlande'},
    'nl-nl': {icon: nlFlag, title: 'Taal', name: 'Nederland'},
  },
}

export const Footer = memo(function Footer(props: {
  alternateLanguages: Array<AllDocumentTypes['alternate_languages'][number] & {parentUid?: string}>
  breadcrumbs?: PageDocumentData['breadcrumbs']
  className?: string
  navigationData: NavigationDocumentData
  pageUid?: string
}) {
  const {linkResolver} = useLinkResolver()
  const router = useRouter()
  const locale = router.locale as Locale
  const [isPopupShown, setIsPopupShown] = useState(false)
  const close = useCallback(() => setIsPopupShown(false), [])

  const showCookieConsent = useCallback(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore -- usercentrics script is loaded by GTM
      window.UC_UI?.showSecondLayer()
    }
  }, [])

  return (
    <footer
      className={cn('grid grid-cols-main gap-y-8 bg-2a2928 pb-20 pt-10 text-ffffff md:gap-y-10', props.className)}
    >
      <div className="col-[inner] grid grid-flow-col items-center justify-start gap-x-3 text-14">
        <Link className="font-bold" href="/">
          {props.navigationData.homepage_label || 'Home'}
        </Link>

        {props.breadcrumbs &&
          props.breadcrumbs.map((item, index) =>
            isFilled.contentRelationship(item.link) ? (
              <Fragment key={index}>
                <Icon className="h-2 w-1" name="arrow-right" />
                <Link href={linkResolver(item.link)}>{item.label}</Link>
              </Fragment>
            ) : null,
          )}
      </div>

      <hr className="col-[inner] border-ffffff/10" />

      <div className="col-[inner] grid gap-x-6 gap-y-8 sm:grid-cols-2 md:grid-cols-4">
        <SliceZone
          context={{isFooter: true, pageUid: props.pageUid} as Context}
          components={components}
          slices={props.navigationData.slices1}
        />
      </div>

      <hr className="col-[inner] border-ffffff/10" />

      <div className="col-[inner] grid gap-y-6 md:col-end-[track-end_8] md:row-start-5 lg:col-end-[track-end_7]">
        <Icon className="h-[21px] w-[91px] text-ffffff" name="logo" />

        <div className="grid items-center justify-start gap-4 sm:grid-flow-col">
          {props.navigationData.underline_links.map((link, index) => (
            <Fragment key={index}>
              <Link
                className="text-14"
                href={linkResolver(link.link)}
                {...(isFilled.link(link.link) && link.link.link_type === 'Web' && {target: link.link.target})}
                isInverted
              >
                {link.label}
              </Link>

              <hr className="hidden h-3 border-l border-t-0 border-l-ffffff sm:block" />
            </Fragment>
          ))}

          <button className="text-start text-14" onClick={showCookieConsent} type="button">
            {props.navigationData.cookie_consent_label}
          </button>
        </div>

        <div className="text-10 text-b8b6b4">
          <PrismicRichText field={props.navigationData.description} />
        </div>

        <div className="grid grid-flow-col justify-start gap-x-6 text-10 text-b8b6b4">
          <span>{props.navigationData.copyright}</span>
          <span>{props.navigationData.additional_text}</span>
        </div>
      </div>

      <div className="col-[inner] row-start-5 flex justify-between gap-y-3 self-start md:col-start-[track-start_9] md:col-end-[track-end_12] md:grid md:grid-cols-4 md:gap-6 lg:col-start-[track-start_10] lg:col-end-[track-end_12] lg:grid-cols-3">
        <div className="col-span-full md:col-start-2 lg:col-start-1">
          <h3 className="text-12 text-e6e6e5">
            {props.navigationData.language_switcher_label || langs[locale || 'en-us'][locale].title}
          </h3>

          <div className="group grid gap-y-2">
            <button
              className="flex items-center gap-x-2 text-14 transition-colors hover:text-72716e active:text-72716e"
              type="button"
            >
              <Image
                alt={`${langs[locale || 'en-us'][locale].name} flag`}
                className="h-4 w-5"
                src={langs[locale || 'en-us'][locale].icon}
              />
              {langs[locale || 'en-us'][locale].name}
            </button>

            <nav className="pointer-events-none invisible grid origin-top-left -skew-x-12 gap-y-2 text-14 opacity-0 transition-all group-hover:pointer-events-auto group-hover:visible group-hover:skew-x-0 group-hover:opacity-100 group-active:pointer-events-auto group-active:visible group-active:skew-x-0 group-active:opacity-100">
              {(props.alternateLanguages || []).map((altLang) => {
                return (
                  <Link
                    className="flex items-center gap-x-2"
                    key={altLang.id}
                    href={altLang.parentUid ? `/${altLang.parentUid}/${altLang.uid}` : `/${altLang.uid}`}
                    locale={altLang.lang}
                  >
                    <Image
                      alt={`${langs[altLang.lang as Locale][altLang.lang as Locale].name} flag`}
                      className="h-4 w-5"
                      src={langs[altLang.lang as Locale][altLang.lang as Locale].icon}
                    />
                    {langs[altLang.lang as Locale][locale].name}
                  </Link>
                )
              })}
            </nav>
          </div>
        </div>
        <div className="col-span-full">
          <SliceZone
            context={{isFooter: true} as Context}
            components={components}
            slices={props.navigationData.slices3}
          />
        </div>
      </div>

      <CookieConsentPopup isShown={isPopupShown} navigationData={props.navigationData} close={close} />
    </footer>
  )
})
