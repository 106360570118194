import {SliceZone} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {NavigationDocumentData, SubmenuDocumentData} from 'prismicio-types'
import {memo, useCallback, useMemo, useRef, useState} from 'react'
import {useClickAway, useWindowSize} from 'react-use'
import {Icon} from 'src/common/Icon'
import dynamic from 'next/dynamic'
import {isFilled} from '@prismicio/client'
import LinksColumn from 'slices/LinksColumn'
import {HamburgerButton} from './HamburgerButton'
import {NavElement} from './NavElement'
import {Button} from 'src/common/Button'
import {useLinkResolver} from 'prismicio'

const NotificationBanner = dynamic(
  () => import('src/common/Layout/NotificationBanner').then((mod) => mod.NotificationBanner),
  {
    ssr: false,
  },
)

export const HeaderV2 = memo(function HeaderV2(props: {
  className?: string
  navigationData: NavigationDocumentData
  isDarkNavigation?: boolean
  pageUid?: string
}) {
  const {linkResolver} = useLinkResolver()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const toggleMenu = useCallback(() => setIsMenuOpen((prevState) => !prevState), [])
  const headerRef = useRef(null)
  useClickAway(headerRef, () => setIsMenuOpen(false))
  const {isDarkNavigation = false} = props
  const isMobile = useWindowSize().width < 1024

  const toggleSubMenu = useCallback(() => {
    setIsSubmenuOpen((prevState) => !prevState)
  }, [])

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) {
      setIsSubmenuOpen(true)
    }
  }, [isMobile])

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) {
      setIsSubmenuOpen(false)
    }
  }, [isMobile])

  const submenus = useMemo(
    () =>
      props.navigationData.menu_links.map((section, index) => {
        if (isFilled.contentRelationship<string, string, SubmenuDocumentData>(section.submenu)) {
          const linksColumns = section.submenu.data?.slices.map((slice) => slice.variation === 'default')
          const shouldHideTitle = Number(linksColumns?.length) <= 1

          return (
            <SliceZone
              key={index}
              components={{links_column: LinksColumn}}
              slices={section.submenu.data?.slices}
              context={{shouldHideTitle, pageUid: props.pageUid}}
            />
          )
        } else {
          return []
        }
      }),
    [props.navigationData.menu_links, props.pageUid],
  )

  return (
    <>
      <header
        className={cn('fixed top-0 z-20 w-full transition-all duration-100', {
          'border-b-[1px] border-grey-135 bg-grey-110/[90%] text-moss-black': !isDarkNavigation,
          'bg-moss-black text-moss-white': isDarkNavigation,
        })}
        ref={headerRef}
        id="header-container"
      >
        {props.navigationData.notification_text && (
          <NotificationBanner
            message={props.navigationData.notification_text}
            linkField={props.navigationData.notification_link}
            linkText={props.navigationData.notification_link_label}
            dataType={props.navigationData.data_type}
            notificationId={props.navigationData.notification_id}
            pageName={props.pageUid}
            images={props.navigationData.notification_image}
          />
        )}
        <div
          className={`relative mx-auto flex h-16 max-w-[1200px] items-center justify-between px-5
          before:absolute
          before:left-0
          before:top-0
          before:-z-10
          before:h-full
          before:w-full
          before:backdrop-blur-[10px]
          before:content-['']
          xl:px-0
          `}
        >
          <Link className="flex" href="/">
            <Icon className="h-6 w-[101px]" name="logo" />
          </Link>

          <nav
            className={cn(
              `left-0 top-[72px] flex w-full flex-col gap-x-8 overscroll-contain rounded-2xl p-6 text-moss-black max-lg:absolute max-lg:max-h-[85vh] max-lg:divide-y max-lg:divide-grey-135 max-lg:overflow-y-auto max-lg:rounded-lg max-lg:bg-moss-white/[72%] max-lg:backdrop-blur-[15px] max-lg:transition-all max-lg:duration-300 lg:top-full lg:col-start-[track-start_3] lg:col-end-[track-end_9] lg:h-full lg:w-full lg:grid-flow-col lg:flex-row lg:items-center lg:justify-center lg:p-0`,
              {
                'max-lg:opacity-0 max-lg:invisible max-lg:pointer-events-none': !isMenuOpen,
                'text-moss-black': !isDarkNavigation,
                'text-moss-white': isDarkNavigation,
              },
            )}
          >
            {props.navigationData.menu_links.map((section, index) => (
              <NavElement
                key={'nav-element-' + index}
                index={index}
                section={section}
                activeSubmenu={submenus[index]}
                isMobile={isMobile}
                isSubmenuOpen={isSubmenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                toggleSubmenu={toggleSubMenu}
                menuLinks={props.navigationData.menu_links.length}
                pageUid={props.pageUid || ''}
              />
            ))}
          </nav>

          <div className="col-start-[track-start_3] col-end-[inner-end] grid grid-flow-col gap-x-2 justify-self-end lg:col-start-[track-start_10]">
            <Button
              className="whitespace-nowrap max-sm:hidden"
              href={linkResolver(props.navigationData.secondary_button_url)}
              variant="outlineV2"
              data-mktg-id={props.navigationData.secondary_button_mktg_id!}
              data-page-location="nav-bar"
              data-page-name={props.pageUid}
              data-type="button"
              id="header-secondary-button"
            >
              {props.navigationData.secondary_button_label}
            </Button>

            <Button
              className="whitespace-nowrap max-sm:hidden"
              data-page-location="nav-bar"
              variant="fillV2"
              href={linkResolver(props.navigationData.primary_button_url, 'primary-button-header')}
              data-mktg-id={props.navigationData.primary_button_mktg_id!}
              data-page-name={props.pageUid}
              data-type="button"
              id="header-primary-button"
            >
              {props.navigationData.primary_button_label}
            </Button>

            <HamburgerButton toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} isDarkNavigation={isDarkNavigation} />
          </div>
        </div>
      </header>
      <div
        className={cn('min-h-16 w-full', {
          'bg-grey-110': !isDarkNavigation,
          'bg-moss-black': isDarkNavigation,
        })}
        id="header-placeholder"
      />
    </>
  )
})
