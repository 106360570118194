import {motion} from 'framer-motion'
import cn from 'classnames'
import {ReactNode, useCallback, useMemo} from 'react'
import {OPACITY_0, OPACITY_1} from 'src/common/constants'

type SubmenuProps = {
  activeIndex: number
  submenu: ReactNode
  shouldRenderBorder: boolean
  isSubmenuOpen: boolean
  setIsMenuOpen: (value: boolean) => void
  isMobile: boolean
}

const desktopAnimation = {
  initial: OPACITY_0,
  animate: OPACITY_1,
  exit: OPACITY_0,
}

const mobileAnimation = {
  initial: {...OPACITY_0, maxHeight: 0},
  animate: {...OPACITY_1, maxHeight: '2200px'},
  exit: {...OPACITY_0, maxHeight: 0},
}

export const Submenu = ({submenu, shouldRenderBorder, isSubmenuOpen, setIsMenuOpen, isMobile}: SubmenuProps) => {
  const animationProps = useMemo(() => (isMobile ? mobileAnimation : desktopAnimation), [isMobile])

  const className = useMemo(
    () =>
      cn(
        `grid-base w-full max-w-[816px] gap-y-0 overflow-visible border-grey-135 max-lg:pb-4 lg:absolute lg:left-[1px] lg:top-[52px] lg:mx-[50%] lg:-translate-x-1/2 lg:overflow-scroll lg:rounded-2xl lg:border lg:border-grey-135 lg:bg-moss-white/[72%] lg:p-6 lg:backdrop-blur-[15px]`,
        {'border-t': shouldRenderBorder},
      ),
    [shouldRenderBorder],
  )

  const handleMouseEnter = useCallback(() => {
    if (!isSubmenuOpen) {
      setIsMenuOpen(true)
    }
  }, [isSubmenuOpen, setIsMenuOpen])

  const handleMouseLeave = useCallback(() => {
    if (isSubmenuOpen) {
      setIsMenuOpen(false)
    }
  }, [isSubmenuOpen, setIsMenuOpen])

  return (
    <motion.div
      {...animationProps}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {submenu}
    </motion.div>
  )
}
