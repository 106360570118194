import cn from 'classnames'
import Head from 'next/head'
import {AllDocumentTypes, HomePageDocumentData, NavigationDocumentData} from 'prismicio-types'
import {ReactNode, memo} from 'react'
import {Footer} from './Footer'
import {HeaderV2} from './HeaderV2'
import {NotificationContextProvider} from 'src/context/NotificationContext'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {HeaderContextProvider} from 'src/context/HeaderContext'
import {useCookie, useEffectOnce} from 'react-use'
import {HrefLangElements} from './HrefElements'
import {ParentUid} from 'pages/[uid]/[subpageUid]'
import {DialogContextProvider} from 'src/context/DialogContext'

export const Layout = memo(function Layout(props: {
  alternateLanguages: Array<AllDocumentTypes['alternate_languages'][number] & {parentUid?: string}>
  breadcrumbs?: HomePageDocumentData['breadcrumbs']
  children: ReactNode
  className?: string
  navigationData: NavigationDocumentData
  mainClassName?: string
  hideNavigation?: boolean
  isDarkNavigation?: boolean
  pageUid?: string
  locale?: string
  parentUids?: ParentUid[]
}) {
  const isProduction = typeof window !== 'undefined' && window.location.host.includes('getmoss.com')
  const hasParentPageUid = props.parentUids?.some((el) => el.lang === props.locale)
  const parentUid = hasParentPageUid ? props.parentUids?.find((el) => el.lang === props.locale)?.uid : null

  const [internalUserCookie, updateInternalUserCookie] = useCookie('internal_user_id')

  // Initiate AOS
  useEffectOnce(() => {
    if (!internalUserCookie) {
      updateInternalUserCookie('true')
    }

    AOS.init({
      duration: 700,
    })
  })

  return (
    <div className={cn('relative grid min-h-screen grid-rows-[auto_1fr_auto] bg-f8f8f8', props.className)}>
      <HrefLangElements
        alternateLanguages={props.alternateLanguages}
        parentUid={parentUid}
        pageUid={props.pageUid}
        locale={props.locale}
      />
      {typeof window !== 'undefined' && (
        <Head>
          {isProduction && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-58T3NG5');
                  `,
                }}
              />
              <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
            </>
          )}
        </Head>
      )}
      <DialogContextProvider>
        <NotificationContextProvider
          includedUids={props.navigationData?.included_uids}
          notificationId={props.navigationData?.notification_id}
        >
          <HeaderContextProvider>
            {!props.hideNavigation && (
              <HeaderV2
                navigationData={props.navigationData}
                isDarkNavigation={props.isDarkNavigation}
                key={Date.now()}
                pageUid={props.pageUid}
              />
            )}
            <main className={props.mainClassName}>{props.children}</main>

            {!props.hideNavigation && (
              <Footer
                alternateLanguages={props.alternateLanguages}
                breadcrumbs={props.breadcrumbs}
                navigationData={props.navigationData}
                pageUid={props.pageUid}
              />
            )}
          </HeaderContextProvider>
        </NotificationContextProvider>
      </DialogContextProvider>
      <div id="dialog-container" />
    </div>
  )
})
