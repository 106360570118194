import {AnimatePresence} from 'framer-motion'
import {LinkWrapper} from 'src/common/LinkWrapper'
import {Submenu} from './Submenu'
import {isFilled} from '@prismicio/client'
import {Icon} from 'src/common/Icon'
import cn from 'classnames'
import {NavigationDocumentDataMenuLinksItem, SubmenuDocumentData} from 'prismicio-types'
import {ReactNode} from 'react'

type NavElementProps = {
  index: number
  section: NavigationDocumentDataMenuLinksItem
  activeSubmenu: ReactNode
  isMobile: boolean
  isSubmenuOpen: boolean
  setIsMenuOpen: (value: boolean) => void
  activeIndex: number
  setActiveIndex: (value: number) => void
  handleMouseEnter: () => void
  handleMouseLeave: () => void
  toggleSubmenu: () => void
  menuLinks: number
  pageUid: string
}

export const NavElement = ({
  index,
  section,
  activeSubmenu,
  isMobile,
  isSubmenuOpen,
  setIsMenuOpen,
  activeIndex,
  setActiveIndex,
  handleMouseEnter,
  handleMouseLeave,
  toggleSubmenu,
  menuLinks,
  pageUid,
}: NavElementProps) => {
  const hasSubmenu = isFilled.contentRelationship<string, string, SubmenuDocumentData>(section.submenu)
  let shouldRenderBorder = false

  if (isFilled.contentRelationship<string, string, SubmenuDocumentData>(section.submenu)) {
    shouldRenderBorder = Number(section.submenu.data?.slices?.length) > 1
  }

  return (
    <div
      key={index}
      className={cn('text-body-bold-s group flex h-full flex-col items-start lg:items-center lg:py-0')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        toggleSubmenu()
        setActiveIndex(index)
      }}
    >
      <LinkWrapper
        className="w-full lg:h-full"
        link={section.link_url}
        mktgId={section.marketingid || ''}
        dataType={`order-${index}`}
        location="main-nav-bar"
        pageName={pageUid}
      >
        <div className="flex h-full w-full items-center justify-between gap-2 py-4 lg:p-0">
          <span
            onMouseEnter={() => setActiveIndex(index)}
            className={cn('block w-full lg:my-auto lg:w-fit', {
              'pt-0': index === 0,
              'pb-0': index === menuLinks - 1,
            })}
          >
            {section.link_label}
          </span>
          {hasSubmenu && (
            <Icon
              name="chevron"
              className={cn('h-4 w-4 transition-all duration-300', {
                'rotate-180': activeIndex === index && isSubmenuOpen,
              })}
            />
          )}
        </div>
      </LinkWrapper>
      <AnimatePresence>
        {isSubmenuOpen && hasSubmenu && activeIndex === index && (
          <Submenu
            activeIndex={activeIndex}
            submenu={activeSubmenu}
            shouldRenderBorder={shouldRenderBorder}
            isSubmenuOpen={isSubmenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            isMobile={isMobile}
          />
        )}
      </AnimatePresence>
    </div>
  )
}
