import {Dispatch, PropsWithChildren, SetStateAction, createContext, useState} from 'react'

export type HeaderContextType = {
  setShouldHideNavigation: Dispatch<SetStateAction<boolean>>
  shouldHideNavigation: boolean
}

export const HeaderContext = createContext<HeaderContextType | {}>({})

export const HeaderContextProvider = ({children}: PropsWithChildren) => {
  const [shouldHide, setShouldHide] = useState(false)

  return (
    <HeaderContext.Provider value={{setShouldHideNavigation: setShouldHide, shouldHideNavigation: shouldHide}}>
      {children}
    </HeaderContext.Provider>
  )
}
