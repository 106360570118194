import Head from 'next/head'
import {usePathname} from 'next/navigation'
import {AllDocumentTypes} from 'prismicio-types'
import {Fragment} from 'react'
import {DEFAULT_LOCALE, GET_MOSS_HOSTNAME} from 'src/common/constants'

type AlternateLanguage = AllDocumentTypes['alternate_languages'][number] & {parentUid?: string}

type HrefLangElementsProps = {
  alternateLanguages: AlternateLanguage[]
  parentUid?: string | null
  pageUid?: string
  locale?: string
}

export const HrefLangElements = ({alternateLanguages, parentUid, pageUid, locale}: HrefLangElementsProps) => {
  const path = usePathname()
  const currentPathIsDefaultLocale = isDefaultLocale(locale)

  return (
    <Head>
      {alternateLanguages.map((altLang, index) => (
        <Fragment key={altLang.id + index}>
          <link
            rel="alternate"
            href={generateHref(altLang.lang, altLang.parentUid, altLang.uid)}
            hrefLang={altLang.lang}
          />
          {isDefaultLocale(altLang.lang) && (
            <link
              rel="alternate"
              href={generateHref(altLang.lang, altLang.parentUid, altLang.uid)}
              hrefLang="x-default"
            />
          )}
        </Fragment>
      ))}
      <link rel="alternate" href={generateHref(locale, parentUid, pageUid)} hrefLang={locale} />
      {currentPathIsDefaultLocale && (
        <link
          key="x-default"
          rel="alternate"
          href={`https://www.getmoss.com${generateUrlPath(parentUid, pageUid)}`}
          hrefLang="x-default"
        />
      )}
      <link rel="canonical" href={`${GET_MOSS_HOSTNAME}${currentPathIsDefaultLocale ? '' : `/${locale}`}${path}`} />
    </Head>
  )
}

const generateHref = (lang?: string, parentUid?: string | null, uid?: string): string => {
  const baseUrl = 'https://www.getmoss.com'
  const langPath = lang === 'en-us' ? '' : `/${lang}`
  const uidPath = parentUid ? `/${parentUid}/${uid}` : `/${uid === 'home-page' ? '' : uid}`
  return `${baseUrl}${langPath}${uidPath}`
}

const generateUrlPath = (parentUid?: string | null, pageUid?: string): string => {
  if (parentUid) {
    return `/${parentUid}/${pageUid}`
  }

  if (pageUid === 'home-page') {
    return ''
  }

  return `/${pageUid}`
}

const isDefaultLocale = (locale?: string) => locale === DEFAULT_LOCALE
