import {KeyTextField} from '@prismicio/client'
import {useParams} from 'next/navigation'
import {createContext, useEffect, useState} from 'react'
import {useEffectOnce, useLocalStorage} from 'react-use'

type NotificationContextType = {
  disableNotification: () => void
  isActive?: boolean
}

type NotificationContextProviderProps = {
  children: React.ReactNode
  includedUids?: KeyTextField
  notificationId?: KeyTextField
}

type NotificationData = {
  isActive: boolean
  isDismissed: boolean
  activeNotificationId: string
}

const initialNotificationData: NotificationData = {
  isActive: false,
  isDismissed: false,
  activeNotificationId: '',
}

export const NotificationContext = createContext<NotificationContextType | null>(null)

export const NotificationContextProvider = ({
  children,
  includedUids,
  notificationId: currentNotificationId,
}: NotificationContextProviderProps) => {
  const {uid, subpageUid} = useParams() || {}
  const hasIncludedUids = includedUids?.length

  const activePath = hasIncludedUids
    ? includedUids.includes(uid as string) || includedUids.includes(subpageUid as string) || uid === undefined
    : true

  const [notificationData, setNotificationData] = useLocalStorage<NotificationData>('notification-data')
  const {isActive, isDismissed, activeNotificationId} = notificationData || initialNotificationData
  const [isMounted, setIsMounted] = useState(false)

  const disableNotification = () => {
    setNotificationData({
      isActive: false,
      isDismissed: true,
      activeNotificationId,
    })
  }

  useEffectOnce(() => {
    setIsMounted(true)

    // Activate notification if it's not the latest id
    if (currentNotificationId !== activeNotificationId) {
      setNotificationData({
        isActive: activePath,
        isDismissed: false,
        activeNotificationId: currentNotificationId!,
      })
    }
  })

  // Update isNotificationEnabled when path changes
  useEffect(() => {
    if (!isMounted) {
      return
    }

    if ((isActive === undefined && !isDismissed) || !isDismissed) {
      setNotificationData({
        isActive: activePath,
        isDismissed: false,
        activeNotificationId: currentNotificationId!,
      })
    }
  }, [activePath, currentNotificationId, isActive, isDismissed, isMounted, setNotificationData, uid])

  return <NotificationContext.Provider value={{isActive, disableNotification}}>{children}</NotificationContext.Provider>
}
